import * as React from "react";
import urlParse from "url-parse";
import { DatasetMetadata } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { filterEmptyVals } from "#helpers/utils.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import { ResourceEditorDescriptions } from "#reducers/resourceEditorDescriptions.js";

const SUPPORTED_IMAGE_EXTENSIONS = ["png", "apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "svg", "webp"];

export interface Props {
  currentAccount: Account;
  currentDs: Dataset;
  resourceDescription?: ResourceEditorDescriptions;
}

function tryProperties(resource: ResourceEditorDescriptions, allowedPredicated: string[]) {
  if (!resource.properties) return undefined;
  for (const predicate of allowedPredicated) {
    if (predicate in resource.properties && resource.properties[predicate]?.length > 0) {
      return resource.properties[predicate];
    }
  }
  return undefined;
}

const ResourceInfoMetadata: React.FC<Props> = ({ currentAccount, currentDs, resourceDescription }) => {
  const constructConsoleUrl = useConstructConsoleUrl();
  // When no resource is displayed or this has no outLinks, return the dataset metadata
  const datasetUrl = urlParse(constructConsoleUrl());
  datasetUrl.set("pathname", `/${currentAccount.accountName}/${currentDs.name}`);
  datasetUrl.set("query", "");
  if (!resourceDescription)
    return (
      <DatasetMetadata
        currentPath={datasetUrl.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title="Data editor"
      />
    );

  const resourceUrl = urlParse(constructConsoleUrl());
  resourceUrl.set("pathname", datasetUrl.pathname + "/data-editor");
  resourceUrl.set("query", `resource=${encodeURIComponent(resourceDescription.value)}`);

  let typeBasedJson:
    | { "@type": "GeoCoordinates"; latitude: number | string; longitude: number | string; additionalType?: string[] } // Can be both string and number see https://schema.org/latitude
    | {
        "@type": "Place";
        geo: {
          latitude: number; // these can't be strings as we gather the point from a wkt
          longitude: number;
          "@id": string;
          "@type": "GeoCoordinates";
        };
        additionalType?: string[];
      }
    | {
        "@type": string | "Property" | "Class" | "Thing";
        domainIncludes?: {
          "@id": string;
          "@type": "Class";
        };
        rangeIncludes?: {
          "@id": string;
          "@type": "Class";
        };

        supersededBy?: {
          "@id": string;
          "@type": "Class";
        };
        additionalType?: string[];
      }
    | undefined;
  if (resourceDescription.typeLabel === "http://www.w3.org/2002/07/owl#Class") {
    typeBasedJson = {
      "@type": "Class",
    };
    const subClassWidget = resourceDescription.properties?.["http://www.w3.org/2000/01/rdf-schema#subClassOf"];
    if (subClassWidget?.length) {
      typeBasedJson.supersededBy = {
        "@id": subClassWidget[0].value,
        "@type": "Class",
      };
    }
  } else if (
    resourceDescription.typeLabel === "http://www.w3.org/2002/07/owl#DatatypeProperty" ||
    resourceDescription.typeLabel === "http://www.w3.org/2002/07/owl#ObjectProperty"
  ) {
    const domainWidget = resourceDescription.properties?.["http://www.w3.org/2000/01/rdf-schema#domain"];
    const rangeWidget = resourceDescription.properties?.["http://www.w3.org/2000/01/rdf-schema#range"];
    typeBasedJson = { "@type": "Property" };
    if (domainWidget?.length) {
      typeBasedJson.domainIncludes = {
        "@id": domainWidget[0].value,
        "@type": "Class",
      };
    }
    if (rangeWidget?.length) {
      typeBasedJson.rangeIncludes = {
        "@id": rangeWidget[0].value,
        "@type": "Class",
      };
    }
  } else {
    typeBasedJson = {
      "@type": resourceDescription.type.includes("://schema.org/")
        ? resourceDescription.type?.split("/").pop() || "Thing"
        : "Thing",
    };
  }

  const additionalTypes = [resourceDescription.type].filter(
    (element: string) => !element.includes(typeBasedJson?.["@type"] || "://schema.org/Thing"),
  );

  if (additionalTypes && typeBasedJson) {
    typeBasedJson.additionalType = additionalTypes;
  }

  const description = tryProperties(resourceDescription, [
    "http://purl.org/dc/terms/description",
    "http://purl.org/dc/elements/1.1/description",
    "https://schema.org/description",
    "http://www.w3.org/ns/shacl#description",
  ])?.[0].value;

  const image = Object.values(resourceDescription.properties || {}).find((property) =>
    property.find((value) => {
      try {
        const url = new URL(value.value);
        return SUPPORTED_IMAGE_EXTENSIONS.includes(url.pathname.split(".").pop() || "");
      } catch {
        return false;
      }
    }),
  )?.[0].value;

  const label =
    resourceDescription.valueLabel ||
    tryProperties(resourceDescription, [
      "http://www.w3.org/2000/01/rdf-schema#label",
      "http://xmlns.com/foaf/0.1/name",
      "https://schema.org/name",
      "http://www.w3.org/2004/02/skos/core#prefLabel",
      "http://purl.org/dc/terms/title",
      "http://purl.org/dc/elements/1.1/title",
      "http://www.w3.org/ns/shacl#name",
    ])?.[0].value;

  const datasetJson = filterEmptyVals({
    "@type": "Dataset",
    "@id": datasetUrl.href,
    name: currentDs.displayName || currentDs.name,
    description: currentDs.description,
    license: currentDs.license,
  });
  const schemaDotOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    url: resourceUrl.href, // Should be full url link
    mainEntity: filterEmptyVals({
      "@id": resourceDescription.value,
      ...typeBasedJson,
      description: description,
      image: image,
      name:
        label ||
        (resourceDescription.value.indexOf("#") >= 0
          ? resourceDescription.value.split("#").slice(-1)[0]
          : resourceDescription.value.split("/").slice(-1)[0]),
      subjectOf: datasetJson,
      url: resourceUrl.href,
    }),
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: ["/html/head/title", "/html/head/meta[@name='description']/@content"],
    },
  };

  return (
    <DatasetMetadata
      currentPath={resourceUrl.pathname + `?resource=${encodeURIComponent(resourceDescription.value)}`}
      currentAccount={currentAccount}
      currentDs={currentDs}
      description={
        description ||
        `Resource ${label || resourceDescription.value} in dataset ${currentDs.displayName || currentDs.name} created by ${
          currentAccount.name || currentAccount.accountName
        }.`
      }
      image={image || currentDs.avatarUrl || currentAccount.avatarUrl}
      imageAlt={label || ""}
      jsonLd={[{ key: "schema", json: schemaDotOrgJsonLd }]}
      longTitle={label || resourceDescription.value}
      title={label || resourceDescription.value}
      type="schema:Thing"
    />
  );
};
export default ResourceInfoMetadata;
