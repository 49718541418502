import { Alert, Avatar, Chip, Container, Paper, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { stringifyQuery } from "@triply/utils-private";
import { HumanizedDate } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { GlobalState } from "#reducers/index.js";
import EditResource from "../Actions/EditResource";
import RemoveResource from "../Actions/RemoveResource";
import ResourceAction from "../Actions/ResourceAction";
import { useEditorProcessContext } from "../Process";
import PropertiesViewer from "./viewers/PropertiesViewer";
import History from "./History";
import * as styles from "./style.scss";

interface Props {
  dsId?: string;
  resource: string;
  className?: string;
  innerClassName?: string;
}

const InstanceView: React.FC<Props> = ({ dsId, resource, className, innerClassName }) => {
  const acl = useAcl();
  const account = useCurrentAccount();
  const currentDs = useCurrentDataset();

  const resourceDescription = useSelector((state: GlobalState) =>
    dsId ? state.resourceEditorDescriptions[dsId]?.resources[resource] : undefined,
  );
  const classDescription = useSelector((state: GlobalState) =>
    dsId && resourceDescription?.type
      ? state.resourceEditorDescriptions[dsId]?.classShapes[resourceDescription.type]
      : undefined,
  );
  const instanceHasShape = !!classDescription?.shapeIri;

  const applyPrefixes = useApplyPrefixes();
  const { getStatusConfigFor, getCreateActions } = useEditorProcessContext();
  const authenticatedUser = useAuthenticatedUser();
  const consoleUrl = useConstructConsoleUrl()();
  const userIri = `${consoleUrl}/${authenticatedUser?.accountName}`;
  const resourceStatus = resourceDescription?.status;
  const authorIsCreationObject =
    resourceDescription?.firstAction &&
    getCreateActions()
      .map((action) => action.name)
      .includes(resourceDescription.firstAction);
  const authorIri = authorIsCreationObject ? resourceDescription?.creator : undefined;
  const createdDate = authorIsCreationObject ? resourceDescription?.createdAt : undefined;
  const sameHistoryEvent = resourceDescription?.createdAt?.toString() === resourceDescription?.editedAt?.toString();
  const modifiedDate = (!sameHistoryEvent || !authorIsCreationObject) && resourceDescription?.editedAt;
  const editorNote = resourceDescription?.note;
  const modifiedByIri = (!sameHistoryEvent || !authorIsCreationObject) && resourceDescription?.editor;
  const authenticatedUserRole = acl.getRoleInAccount(account);

  const config = getStatusConfigFor(resourceStatus);
  if (resource && !resourceDescription)
    return (
      <Paper className={getClassName(styles.noResourceContainer)}>
        <Alert severity="info">
          Resource{" "}
          <a href={resource} rel="noopener noreferrer">
            {resource}
          </a>{" "}
          not found.
        </Alert>
      </Paper>
    );
  if (!resource)
    return (
      <Paper className={getClassName(styles.noResourceContainer)}>
        <Alert severity="info">No resources selected.</Alert>
      </Paper>
    );
  return (
    <Paper className={getClassName("p-5", className)}>
      <Container className={getClassName("flex column g-5", innerClassName)}>
        <Stack gap={1} direction={"row"} alignItems={"flex-start"} flexWrap="wrap">
          <div className="my-2">
            <Typography variant="h4" component={"h1"}>
              {resourceDescription?.valueLabel || applyPrefixes(resourceDescription?.value)}
            </Typography>
            <a href={resourceDescription?.value} rel="noopener noreferrer" target="_blank">
              {applyPrefixes(resourceDescription?.value)}
            </a>{" "}
            <Link
              draggable
              onDragStart={(e) => e.dataTransfer.setData("text/plain", resourceDescription?.type || "")}
              to={{
                pathname: `/${currentDs!.owner.accountName}/${currentDs!.name}/data-model`,
                search: stringifyQuery({ resource: resourceDescription?.type }),
              }}
            >
              <Chip label={resourceDescription?.typeLabel || applyPrefixes(resourceDescription?.type)} />
            </Link>
          </div>
          <div className="grow" />
          <div className="flex g-2">
            {instanceHasShape &&
              config?.actions.map((action) => {
                switch (action.type) {
                  case "create":
                    // Create actions should be handled in the Toolbar
                    return null;
                  case "delete":
                    return (
                      <RemoveResource
                        color={action.color}
                        name={action.name}
                        fromStatus={config.status.name}
                        key={action.name + action.type}
                      />
                    );
                  case "edit":
                    return (
                      <EditResource
                        key={action.name + action.type}
                        resource={resource}
                        color={action.color}
                        name={action.name}
                        toStatus={action.toState}
                        fromStatus={config.status.name}
                      />
                    );
                  case "updateStatus":
                    return (
                      <ResourceAction
                        key={action.name + action.type}
                        buttonLabel={action.name}
                        color={action.color === "default" ? undefined : action.color}
                        confirmationLabel="Update status"
                        newStatus={action.toState}
                        fromStatus={config.status.name}
                        title={action.name}
                        disabled={
                          action.forceReview &&
                          (modifiedByIri || authorIri) === userIri &&
                          authenticatedUserRole !== "owner"
                            ? "You are not allowed to do this action as you edited this resource."
                            : undefined
                        }
                      />
                    );
                }
              })}
          </div>
        </Stack>
        {((config?.status && config?.status.name !== "unknown") ||
          modifiedByIri ||
          modifiedDate ||
          authorIri ||
          createdDate) &&
          instanceHasShape && (
            <Toolbar className="flex wrap center g-3" component={(props) => <Paper {...props} variant="outlined" />}>
              <div className="flex g-3 center">
                {config?.status.name !== "unknown" && (
                  <Stack>
                    <Typography variant="caption">Status</Typography>
                    <Chip
                      label={config?.status.name}
                      color={config?.status?.color === "default" ? undefined : config?.status?.color}
                    />
                  </Stack>
                )}
                {modifiedByIri && (
                  <Stack>
                    <Typography variant="caption">Modified by</Typography>
                    <Chip
                      label={modifiedByIri.split("/").pop()}
                      avatar={<Avatar>{modifiedByIri.split("/").pop()?.[0] || "?"}</Avatar>}
                    />
                  </Stack>
                )}
                {modifiedDate && (
                  <Stack>
                    <Typography variant="caption">Modified</Typography>
                    <Chip
                      label={<HumanizedDate date={modifiedDate} />}
                      variant="outlined"
                      className={styles.borderless}
                    />
                  </Stack>
                )}
                {authorIri && (
                  <Stack>
                    <Typography variant="caption">Author</Typography>
                    <Chip
                      label={authorIri.split("/").pop()}
                      avatar={<Avatar>{authorIri.split("/").pop()?.[0] || "?"}</Avatar>}
                    />
                  </Stack>
                )}
                {createdDate && (
                  <Stack>
                    <Typography variant="caption">Created</Typography>
                    <Chip
                      label={<HumanizedDate date={createdDate} />}
                      variant="outlined"
                      className={styles.borderless}
                    />
                  </Stack>
                )}
              </div>
              <History />
            </Toolbar>
          )}
        {editorNote && (
          <Alert severity="info" className="my-5">
            {editorNote}
          </Alert>
        )}
        {dsId && resourceDescription && <PropertiesViewer dsId={dsId} description={resourceDescription} />}
      </Container>
    </Paper>
  );
};

export default React.memo(InstanceView);
