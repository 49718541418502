import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Chip, IconButton, Stack } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { Dialog, FontAwesomeIcon, HumanizedDate } from "#components/index.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { useEditorProcessContext } from "../Process";
import { EditorActionType } from "../Process/processDefinitions";
import * as styles from "./style.scss";

const factory = factories.compliant;

interface History {
  time: Date;
  actionLabel: string;
  editor?: string | null;
  toStatus?: string | null;
  fromStatus?: string | null;
  note?: string | null;
  summary?: string | null;
}

interface ActionElement {
  type: "action";
  time: Date;
  action?: string | null;
  note?: string | null;
  by?: string | null;
  summary?: string | null;
}
interface StatusElement {
  type: "status";
  status?: string | null;
}

interface Props {}

function historyToTimelineElements(history: History[]): (ActionElement | StatusElement)[] {
  const items: (ActionElement | StatusElement)[] = [];
  let lastStatus: null | undefined | string = null;
  for (const historyItem of history) {
    if (lastStatus !== historyItem.fromStatus && historyItem.fromStatus) {
      items.push({ type: "status", status: historyItem.fromStatus });
    }
    items.push({
      type: "action",
      time: historyItem.time,
      action: historyItem.actionLabel,
      note: historyItem.note,
      by: historyItem.editor,
      summary: historyItem.summary,
    });
    if (lastStatus !== historyItem.toStatus && historyItem.toStatus) {
      items.push({
        type: "status",
        status: historyItem.toStatus,
      });
    }
    lastStatus = historyItem.toStatus;
  }
  return items;
}

function getIconForAction(actionType: EditorActionType): IconName {
  switch (actionType) {
    case "delete":
      return "trash";
    case "edit":
      return "pencil";
    case "updateStatus":
      return "stamp";
    case "create":
      return "plus";
    case "unknown":
      return "question";
  }
}

const History: React.FC<Props> = ({}) => {
  const [open, setOpen] = React.useState(false);
  const resource = useCurrentResource();
  const { getActionConfigFor, getStatusConfigFor } = useEditorProcessContext();
  const { data } = useSparql<History[]>(`
    prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>
    select distinct * where {
      bind(${termToString(factory.namedNode(resource))} as ?iri)
      ?historyIri meta:product ?iri;
                  a meta:Event;
                  meta:action ?actionLabel
      optional {
        ?historyIri meta:time ?time
      }
      optional {
        ?historyIri meta:actor ?editor
      }
      optional {
        ?historyIri meta:toStatus ?toStatus
      }
      optional {
        ?historyIri meta:fromStatus ?fromStatus
      }
      optional {
        ?historyIri meta:editorialNote ?note
      }
      optional {
        ?historyIri meta:summary ?summary
      }
    } order by ?time
    `);
  const items = data ? historyToTimelineElements(data) : [];
  return (
    <>
      <IconButton title="History" aria-label="See history" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="clock-rotate-left" />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth closeButton>
        <Timeline className={getClassName("mt-7", styles.timeline)}>
          {items &&
            items.map((element, idx) => {
              if (element.type === "action") {
                const renderConfig = getActionConfigFor(element.action || undefined);
                const color = renderConfig.color === "default" ? undefined : renderConfig.color;

                return (
                  <TimelineItem key={idx}>
                    <TimelineOppositeContent>
                      <Stack>
                        <div>
                          <Chip
                            label={element.by?.split("/").pop()}
                            avatar={<Avatar>{element.by?.split("/").pop()?.[0] || "?"}</Avatar>}
                          />
                        </div>
                        <HumanizedDate date={element.time} />
                      </Stack>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <div className={getClassName("my-3", "flex", "horizontalCenter", styles.timelineDot)}>
                        <Chip
                          variant="outlined"
                          icon={<FontAwesomeIcon icon={getIconForAction(renderConfig.type)} fixedWidth />}
                          label={renderConfig.name}
                          color={color}
                        />
                      </div>
                      {idx < items.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      {element.note && <Alert severity="info">{element.note}</Alert>}
                      {element.summary && (
                        <Accordion variant="outlined">
                          <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon={["fas", "caret-down"]} />}>
                            Changes
                          </AccordionSummary>
                          <AccordionDetails className={styles.keepLines}>{element.summary}</AccordionDetails>
                        </Accordion>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                );
              } else {
                const renderConfig = getStatusConfigFor(element.status || undefined).status;
                if (renderConfig.name === "unknown") return null;
                const color = renderConfig.color === "default" ? undefined : renderConfig.color;
                return (
                  <TimelineItem key={idx}>
                    <TimelineOppositeContent />
                    <TimelineSeparator>
                      <div className={getClassName("my-3", "flex", "horizontalCenter", styles.timelineDot)}>
                        <Chip color={color} label={renderConfig.name} />
                      </div>
                      {idx < items.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent />
                  </TimelineItem>
                );
              }
            })}
        </Timeline>
      </Dialog>
    </>
  );
};

export default History;
