import { generateColor } from "@marko19907/string-to-color";
import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import { debounce } from "@mui/material/utils";
import { escapeRegExp } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { getLocalNameInfo } from "@triply/utils/prefixUtils.js";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { setLastDataEditorResource } from "#reducers/datasets.ts";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { stringifyQuery } from "../../../helpers/utils";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import { sparqlSearch } from "./tree/queries";
import { COLOR_GENERATE_OPTIONS } from "./SkosTree";
import * as styles from "./style.scss";

export interface ConceptSchemeData {
  conceptScheme: string;
  conceptSchemeLabel: string;
}

interface Concept {
  id: string;
  label: string;
  scheme: string;
  schemeLabel?: string;
}

export const SearchField: React.FC<{ selectedSchemes: string[] }> = ({ selectedSchemes }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedConcept, setSelectedConcept] = React.useState<Concept | null>(null);
  const [options, setOptions] = React.useState<readonly Concept[]>([]);
  const [loading, setLoading] = React.useState(false);
  const search = useCurrentSearch();
  const dispatch = useDispatch();

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const { accountName } = currentDs.owner;
  const datasetName = currentDs.name;

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: accountName,
          dataset: datasetName,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    [sparqlUrl, accountName, datasetName],
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Concept[]) => void,
        ) => {
          searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\").toLocaleLowerCase();
          setLoading(true);
          sparql(sparqlSearch(searchTerm, selectedSchemes), abortSignal)
            .then(callback)
            .catch(() => {})
            .finally(() => setLoading(false));
        },
        200,
      ),
    [sparql, selectedSchemes],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (searchTerm === "") {
      setSelectedConcept(null);
      setOptions([]);
      return;
    }

    debouncedQuery({ searchTerm: searchTerm, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        setOptions(results?.length ? results : []);
        if (!results?.length) setSelectedConcept(null);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [searchTerm, debouncedQuery]);

  const history = useHistory();

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
      options={options}
      clearIcon={false}
      fullWidth
      value={selectedConcept}
      noOptionsText="No results"
      getOptionKey={(option) => option.id}
      onChange={(_event: any, newValue: Concept | null, reason) => {
        if (reason === "selectOption") {
          if (newValue?.id) {
            setSelectedConcept(newValue);
            dispatch(setLastDataEditorResource(currentDs.id, newValue.id));
            history.push({
              search: stringifyQuery({ ...search, resource: newValue.id }),
            });
          }

          setSearchTerm("");
        } else {
          setSelectedConcept(null);
        }
      }}
      renderOption={(props, option) => {
        const schemeColor = generateColor(option.scheme, COLOR_GENERATE_OPTIONS);
        return (
          <li {...props}>
            <span className={styles.skosSearchFieldOptionText}>{option.label}</span>
            <Chip
              style={{ background: schemeColor }}
              label={option.schemeLabel || getLocalNameInfo(option.scheme).localName}
              size="small"
            />
          </li>
        );
      }}
      onInputChange={(_event, newSearchTerm) => setSearchTerm(newSearchTerm)}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Search in hierarchy"
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
            }}
          />
        );
      }}
    />
  );
};
