import { Alert, List, ListItem, NoSsr } from "@mui/material";
import * as React from "react";
import {
  Geometry,
  GeometryCollection,
  lexicalToValue,
  lexicalToValue as parseWkt,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "@triplydb/recognized-datatypes/wkt.js";
import { geoProject } from "@triplydb/utils/GeoProject.js";
import { Binding, isAskResponse, isConstructResponse, SparqlResponse } from "../../../SparqlUtils";
import ExampleQuery from "../../ExampleQuery";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import { EmptyResults } from "../displayUtils";
import ErrorResponse from "../Error";
import LargeResultWidget from "../LargeResultWidget";
import * as styles from "./styles.scss";

export type MarkerType = "Default" | "Grouped" | "Heatmap";
export type Perspective = "Top" | "Tilted";
export interface PluginConfig {
  cameraPosition?: {
    lat: number;
    lng: number;
    zoom: number;
    pitch: number;
    bearing: number;
  };
  defaultBearing?: number;
  layers?: string[];
  markerType?: MarkerType;
  perspective?: Perspective;
  tileServer3D?: string;
  tileServer3DOpacity?: number;
}

const GEO_JSON_GEOMETRY_TYPES = [
  "GeometryCollection",
  "LineString",
  "MultiLineString",
  "MultiPoint",
  "MultiPolygon",
  "Point",
  "Polygon",
];

export function isGeoJsonObject(
  geometry: Geometry,
): geometry is Point | MultiLineString | LineString | MultiPoint | Polygon | MultiPolygon | GeometryCollection {
  return GEO_JSON_GEOMETRY_TYPES.includes(geometry.type);
}

const WKT_LITERAL_IRI = "http://www.opengis.net/ont/geosparql#wktLiteral";

const EXAMPLE_QUERY = `
prefix geo: <http://www.opengis.net/ont/geosparql#>
select * where {
  values (?point ?pointTooltip ?pointLabel ?pointColor) {
          ("Point (-123.393333 -48.876667)"^^geo:wktLiteral
          "Point Nemo"
          "Point Nemo is the place in the ocean that is farthest from land"
          "blue")
  }
}`;

export const canDraw = (data?: SparqlResponse) => {
  if (!data) return false;
  if (isConstructResponse(data)) {
    return getGeoVariables(data, ["object"]).length > 0;
  } else if (isAskResponse(data)) {
    return false;
  } else {
    return getGeoVariables(data.results.bindings, data.head.vars).length > 0;
  }
};

export function getGeoVariables(bindings: Binding[], variables: string[]): string[] {
  if (!bindings || bindings.length === 0) {
    return [];
  }
  const geoVars: string[] = [];
  const checkedVars: string[] = [];
  for (const binding of bindings) {
    //we'd like to have checked at least 1 value for all variables. So keep looping
    //in case the first row does not contain values for all bound vars (e.g. optional)
    for (const bindingVar in binding) {
      const term = binding[bindingVar];
      if (term !== undefined && !checkedVars.includes(bindingVar)) {
        checkedVars.push(bindingVar);
        if ("datatype" in term && term.datatype === WKT_LITERAL_IRI) {
          try {
            // CRS84 is geo library default crs
            const wkt = geoProject(lexicalToValue(term.value), "http://www.opengis.net/def/crs/OGC/1.3/CRS84");
            if (isGeoJsonObject(wkt)) geoVars.push(bindingVar);
          } catch (e) {
            // Invalid wkt or unsupported CRS
          }
        }
      }
    }
    if (checkedVars.length === variables.length) {
      //checked all vars. can break now
      break;
    }
  }
  return geoVars;
}
/** Variant of getGeoVariables, which returns why some variables are not geoVariables */
function getReasonForNoGeoVariables(bindings: Binding[], variables: string[]) {
  if (!bindings || bindings.length === 0) {
    return [];
  }
  const reasons: { variable: string; message: string }[] = [];
  const checkedVars: string[] = [];
  for (const binding of bindings) {
    //we'd like to have checked at least 1 value for all variables. So keep looping
    //in case the first row does not contain values for all bound vars (e.g. optional)
    for (const bindingVar in binding) {
      const term = binding[bindingVar];
      if (term !== undefined && !checkedVars.includes(bindingVar)) {
        checkedVars.push(bindingVar);
        if ("datatype" in term && term.datatype === WKT_LITERAL_IRI) {
          try {
            // CRS84 is geo library default crs
            const wkt = lexicalToValue(term.value);
            if (!isGeoJsonObject(wkt)) {
              reasons.push({
                variable: bindingVar,
                message: "Unsupported geometry types",
              });
            }
            try {
              geoProject(wkt, "http://www.opengis.net/def/crs/OGC/1.3/CRS84");
            } catch (e) {
              reasons.push({
                variable: bindingVar,
                message: "Unsupported CRS",
              });
            }
          } catch (e) {
            reasons.push({
              variable: bindingVar,
              message: "Invalid WKT",
            });
          }
        }
      }
    }
    if (checkedVars.length === variables.length) {
      //checked all vars. can break now
      break;
    }
  }
  return reasons;
}

interface Props {}

const Geo = React.lazy(() => import("./Geo"));

const GeoContainer: React.FC<Props> = ({}) => {
  const { results, variables, emptyResults, noResults, isAsk } = useSparqlResults();
  const { checkLimits, responseSize, setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showLargeResult, setShowLargeResult] = React.useState(false);

  if (noResults) return null;
  // Order is important, Since we rely on the content of the data, we first need to check the amount of results, before checking the content
  if (emptyResults) {
    return <EmptyResults />;
  }
  if (isAsk) {
    return !!setVisualizationConfig ? (
      <Alert severity="warning" role="alert">
        Couldn't render the Geo visualization. Check the documentation using the button at the right of the menu above
        for more information. <ExampleQuery query={EXAMPLE_QUERY} name="Geo example" visualization="Geo" />
      </Alert>
    ) : (
      <ErrorResponse error={new Error("Couldn't render the Geo visualization.")} />
    );
  }
  if (getGeoVariables(results, variables).length === 0) {
    if (!setVisualizationConfig) {
      return <ErrorResponse error={new Error("No valid WKT literals")} />;
    }
    const reasons = getReasonForNoGeoVariables(results, variables);
    if (reasons.length === 0) {
      return (
        <Alert severity="warning" role="alert">
          Couldn't render the Geo visualization as no WKT literal is provided. Check the documentation using the button
          at the right of the menu above for more information.{" "}
          <ExampleQuery query={EXAMPLE_QUERY} name="Geo example" visualization="Geo" />
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning" role="alert">
          Couldn't render the Geo visualization as there are some parsing issues for the following variables:
          <List dense>
            {reasons.map((reason) => (
              <ListItem key={reason.variable}>
                <code>{reason.variable}</code>: {reason.message}
              </ListItem>
            ))}
          </List>
          Check the documentation using the button at the right of the menu above for more information.{" "}
          <ExampleQuery query={EXAMPLE_QUERY} name="Geo example" visualization="Geo" />
        </Alert>
      );
    }
  }
  if (checkLimits && !showLargeResult && responseSize !== undefined && responseSize > 2 * 1024 * 1024) {
    return <LargeResultWidget exceededSize="resultSize" onContinue={() => setShowLargeResult(true)} />;
  }

  return (
    <NoSsr>
      <div className={styles.visContainer} ref={containerRef}>
        <React.Suspense>
          <Geo container={containerRef} />
        </React.Suspense>
      </div>
    </NoSsr>
  );
};

export default GeoContainer;
