import { Alert, Paper } from "@mui/material";
import * as React from "react";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import PropertiesViewer from "./PropertiesViewer";
import { DashViewer, DashViewerProps } from ".";

const DetailsViewerComponent: React.FC<DashViewerProps> = ({ property }) => {
  const currentDs = useCurrentDataset();
  if (property.nodeKind !== "NestedNode") return <Alert severity="error">Wrongly configured DetailsViewer</Alert>;
  if (property.properties === undefined) return <span>{property.value}</span>;
  if (!currentDs?.id) return null;

  return (
    <Paper variant="outlined" className="p-3">
      <PropertiesViewer dsId={currentDs.id} description={property} />
    </Paper>
  );
};

const DetailsViewer: DashViewer = {
  component: DetailsViewerComponent,
  getScore({ nodeKind }) {
    if (nodeKind === "NestedNode") return 1;
    return 0;
  },
};

export default DetailsViewer;
