import { createContext, ReactNode, useContext, useEffect } from "react";
import * as React from "react";
import useSparql from "#helpers/hooks/useSparql.ts";

const cachedSparqlContext = createContext(new Map());

export function CachedSparqlContext({ children }: { children: ReactNode }) {
  const cache = React.useRef(new Map());

  useEffect(() => {
    const cacheReference = cache.current;

    return () => {
      cacheReference.clear();
    };
  }, []);

  return <cachedSparqlContext.Provider value={cache.current}>{children}</cachedSparqlContext.Provider>;
}

export function useCachedSparql<T>(query: string | false): ReturnType<typeof useSparql<T>> {
  const cache = useContext(cachedSparqlContext);

  const { data: freshData, loading, error, resultsFor } = useSparql<T>(cache.has(query) ? false : query);

  useEffect(() => {
    if (!cache.has(query) && freshData && resultsFor === query) cache.set(query, freshData);
  }, [freshData, cache, query, resultsFor]);

  return { data: (cache.has(query) ? cache.get(query) : freshData) as T, loading, error, resultsFor };
}
