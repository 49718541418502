import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import * as React from "react";
import { SparqlTermResult } from "../SkosTree";
import { expandedContext } from "./ExpandedContext";
import { TreeItem } from "./TreeItem";
import * as styles from "../style.scss";

export type TreeTopItemProps = {
  item: SparqlTermResult;
  virtualItem: VirtualItem;
  isExpandable?: boolean;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
};

export function TreeTopItem({ virtualItem, item, isExpandable, virtualizer }: TreeTopItemProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { expandedMap } = React.useContext(expandedContext);

  const measureAndUpdateHeight = React.useCallback(() => {
    if (ref.current) ref.current.style.height = "auto";
    virtualizer.measureElement(ref.current);
  }, [ref, virtualizer]);

  React.useEffect(() => {
    if (ref.current) ref.current.style.height = "auto";
    requestAnimationFrame(() => virtualizer.measureElement(ref.current));
  }, [expandedMap, item, virtualizer]);
  return (
    <div
      key={virtualItem.key}
      data-index={virtualItem.index}
      ref={ref}
      className={styles.virtualItem}
      style={{
        height: `${virtualItem.size}px`,
        transform: `translateY(${virtualItem.start}px)`,
      }}
    >
      <TreeItem item={item} reportMeasurement={measureAndUpdateHeight} isExpandable={isExpandable} />
    </div>
  );
}
export default React.memo(TreeTopItem);
