import pQueue from "p-queue";
import * as React from "react";
import { CachePolicies } from "use-http";
import useFetch from "#helpers/hooks/useFetch.ts";
import {
  CacheHeader,
  isAskResponse,
  postProcessSparqlJsonResponse,
  postProcessTriplesResponse,
  SparqlJson,
} from "../../components/Sparql/SparqlUtils.ts";

const MAX_RESULT_SIZE = 5 * 1024 * 1024;

const QueueContext = React.createContext<(fn: () => Promise<void>) => Promise<void>>((fn) => fn());

export const SparqlQueryQueue: React.FC<{ children: React.ReactElement; concurrency: number }> = ({
  children,
  concurrency,
}) => {
  const queue = React.useMemo(() => new pQueue({ concurrency }), [concurrency]);
  const cb = React.useCallback((fn: () => Promise<void>) => queue.add(fn), [queue]);
  return <QueueContext.Provider value={cb}>{children}</QueueContext.Provider>;
};

const useSparqlQuery = ({
  endpoint,
  savedQueryId,
  checkLimits,
}: {
  endpoint: string;
  savedQueryId: string | undefined;
  checkLimits?: boolean;
}) => {
  const [queryDuration, setQueryDuration] = React.useState<number>();
  const queue = React.useContext(QueueContext);
  const {
    post,
    abort: abortQuery,
    loading,
    data: rawData,
    response,
    error,
  } = useFetch<SparqlJson | string>(endpoint, {
    cachePolicy: CachePolicies.NO_CACHE,
    credentials: "same-origin",
    headers: {
      Accept: "application/sparql-results+json, application/n-triples",
      "Content-Type": "application/json",
      ...(savedQueryId ? { "x-t-queryid": savedQueryId } : {}),
    }, // static headers only, useFetch only uses the initial value of the headers option.
  });
  const [aborted, setAborted] = React.useState(false);
  const executeQuery = React.useCallback(
    (query: string) => {
      queue(async () => {
        setAborted(false);
        const start = Date.now();
        await post({ query: query })
          .then(() => {
            setQueryDuration(Date.now() - start);
          })
          .catch((_e) => {
            // should be caught by the fetch library
          });
      }).catch(() => {
        // should be caught by the fetch library
      });
    },
    [post, queue],
  );
  const responseSize = response?.headers?.get("X-Triply-length");

  const abort = React.useCallback(() => {
    setAborted(true);
    abortQuery();
  }, [abortQuery]);
  const { data, postProcessingError } = React.useMemo(() => {
    if (checkLimits && responseSize && +responseSize > MAX_RESULT_SIZE) {
      return { data: undefined, postProcessingError: undefined };
    }
    if (typeof rawData === "string") {
      try {
        return { data: postProcessTriplesResponse(rawData), postProcessingError: undefined };
      } catch (e) {
        if (e instanceof Error) {
          return { data: undefined, postProcessingError: e };
        } else {
          throw e;
        }
      }
    } else if (isAskResponse(rawData)) {
      return { data: rawData, postProcessingError: undefined };
    } else if (response.ok === true && rawData !== undefined) {
      try {
        return { data: postProcessSparqlJsonResponse(rawData), postProcessingError: undefined };
      } catch (e) {
        if (e instanceof Error) {
          return { data: undefined, postProcessingError: e };
        } else {
          throw e;
        }
      }
    } else {
      return { data: undefined, postProcessingError: undefined };
    }
  }, [rawData, response.ok, responseSize, checkLimits]);

  if (error && error instanceof Error && rawData && typeof rawData !== "string" && "message" in rawData) {
    error.message = rawData.message as any;
  }

  const queryDelay = Number(response?.headers?.get("X-T-Delay-In-Ms") || 0);
  const cache = response?.headers?.get("X-T-Cache") as CacheHeader | undefined;
  const zeroResultOperationLocations = response?.headers
    ?.get("X-Triply-noResults")
    ?.split(" ")
    .map((segment) => {
      const [startLine, startColumn, endLine, endColumn] = segment.split(",");
      return {
        startLine: +startLine,
        startColumn: +startColumn,
        endLine: +endLine,
        endColumn: +endColumn,
      };
    });

  return {
    executeQuery: executeQuery,
    loading: loading,
    queryDuration: queryDuration,
    queryDelay: aborted ? undefined : queryDelay,
    cache: aborted ? undefined : cache,
    error: aborted ? undefined : error || postProcessingError,
    data: error ? undefined : data,
    rawResponse: rawData,
    abort: abort,
    responseSize: responseSize ? +responseSize : undefined,
    zeroResultOperationLocations: zeroResultOperationLocations,
  };
};

export default useSparqlQuery;
