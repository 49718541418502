import * as React from "react";
import { useCachedSparql } from "../useCachedSparql";
import { allSchemesQuery, conceptSchemeHierarchy } from "./queries";
import { Scheme } from "./SchemeSelector";

interface Hierarchy {
  parentConceptScheme: string;
  childConceptScheme: string;
}

interface SkosTreeContext {
  schemes: Scheme[];
  hierarchy: Hierarchy[];
  loading: boolean;
}

export const skosTreeContext = React.createContext<SkosTreeContext>({ schemes: [], hierarchy: [], loading: false });

const SkosTreeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: schemes = [], loading: schemesLoading } = useCachedSparql<Scheme[]>(allSchemesQuery());
  const { data: hierarchy = [], loading: hierarchyLoading } =
    useCachedSparql<{ parentConceptScheme: string; childConceptScheme: string }[]>(conceptSchemeHierarchy());

  return (
    <skosTreeContext.Provider value={{ schemes, hierarchy, loading: schemesLoading || hierarchyLoading }}>
      {children}
    </skosTreeContext.Provider>
  );
};

export default SkosTreeContextProvider;
