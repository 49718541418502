import { ColorOptions, Xorwow } from "@marko19907/string-to-color";
import { Alert, Box, LinearProgress, Paper } from "@mui/material";
import { useVirtualizer, Virtualizer } from "@tanstack/react-virtual";
import getClassName from "classnames";
import * as React from "react";
import useResizeObserver from "use-resize-observer";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useHideFooter from "#helpers/hooks/useHideFooter.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { expandedContext } from "./tree/ExpandedContext";
import { sparqlFetchExpandable, sparqlFetchTopLevelConcepts } from "./tree/queries";
import SchemeSelector from "./tree/SchemeSelector";
import { skosTreeContext } from "./tree/SkosTreeContext";
import TreeTopItem from "./tree/TreeTopItem";
import { useCachedSparql } from "./useCachedSparql";
import * as styles from "./style.scss";

// Picked a different algorithm here so that the colors are a bit further apart
export const COLOR_GENERATE_OPTIONS: ColorOptions = { lightness: 90, algorithm: Xorwow };

export type SparqlTermResult = {
  concept: string;
  conceptLabel: string;
  scheme: string;
  isExpandable?: string;
};

export type SparqlSchemeResult = {
  scheme: string;
  schemeTitle: string;
};

export type SkosTreeProps = {
  className: string;
};

const SkosTree: React.FC<SkosTreeProps> = ({ className }) => {
  const search = useCurrentSearch();
  const resource = useCurrentResource();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const selectedSchemes = conceptSchemesQueryString.split(",").filter(Boolean);
  const { data, loading: dataLoading } = useCachedSparql<SparqlTermResult[]>(
    !!selectedSchemes.length && sparqlFetchTopLevelConcepts(selectedSchemes),
  );
  const { schemes, loading: globalLoading } = React.useContext(skosTreeContext);
  const expandableCache = React.useRef<Map<string, boolean>>(new Map());
  const scrollWrapperRef = React.useRef<HTMLDivElement>(null);

  useHideFooter();
  const rowVirtualizer = useVirtualizer({
    count: data?.length ?? 0,
    getScrollElement: () => scrollWrapperRef.current,
    estimateSize: () => 38,
    overscan: 40,
  });

  const { ref: resizeObserverRef, height: filtersHeight = 150 } = useResizeObserver();
  const { ref: parentRef, height: allHeight = 350 } = useResizeObserver();

  const { activeMap } = React.useContext(expandedContext);

  React.useEffect(() => {
    const items = Array.from(scrollWrapperRef.current?.children?.[0]?.children ?? []) as HTMLDivElement[];
    if (items) {
      for (const item of Array.from(items)) {
        item.style.height = "auto";
        rowVirtualizer.measureElement(item);
      }
    }
  }, [rowVirtualizer]);

  React.useEffect(() => {
    if (data?.length && dataLoading) return;
    const firstActive = Object.keys(activeMap[conceptSchemesQueryString] ?? {})?.[0];
    if (firstActive) {
      const activeIndex = data?.findIndex((item) => item.concept === firstActive);
      if (activeIndex === -1 || typeof activeIndex !== "number") return;

      rowVirtualizer.scrollToIndex(activeIndex, { align: "start" });
    }
  }, [conceptSchemesQueryString, activeMap, data, dataLoading, resource, rowVirtualizer]);

  return (
    <Paper className={getClassName(className, "p-3", "noShrink")} ref={parentRef}>
      <Box ref={resizeObserverRef}>
        <SchemeSelector />
        <Box className={getClassName("flex", styles.progressBar)}>
          {dataLoading && (
            <LinearProgress color="primary" className={getClassName(styles.treeLoading, "my-2 flex grow")} />
          )}
        </Box>

        {!selectedSchemes.length && (
          <Alert severity="info">{`${!globalLoading && schemes.length === 0 ? "No SKOS concept scheme detected" : "Start by selecting a concept scheme"}`}</Alert>
        )}
      </Box>

      <div className="flex column grow">
        {data && !dataLoading && selectedSchemes.length ? (
          <div ref={scrollWrapperRef} className={styles.innerTree} style={{ height: allHeight - filtersHeight }}>
            <InnerTree rowVirtualizer={rowVirtualizer} data={data} expandableCache={expandableCache.current} />
          </div>
        ) : null}
      </div>
    </Paper>
  );
};

export default SkosTree;

type InnerTreeProps = {
  data: SparqlTermResult[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  expandableCache: Map<string, boolean>;
};

const InnerTree: React.FC<InnerTreeProps> = ({ data, expandableCache, rowVirtualizer }) => {
  const dataset = useCurrentDataset();
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  const terms = React.useMemo(() => virtualItems.map((virtualItem) => data[virtualItem.index]), [data, virtualItems]);
  const [dataExpandable, setDataExpandable] = React.useState<Map<string, boolean>>(new Map(expandableCache.entries()));
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const selectedSchemes = React.useMemo(
    () => conceptSchemesQueryString.split(",").filter(Boolean),
    [conceptSchemesQueryString],
  );

  const fetchExpandable = useDebounce(
    (terms: SparqlTermResult[], selectedSchemes: string[], conceptSchemesQueryString: string) => {
      if (!dataset) return;

      const uncachedTerms = terms.filter(
        (term) => !expandableCache.has(conceptSchemesQueryString + ":" + term.concept),
      );
      const cachedTerms = terms.filter((term) => expandableCache.has(conceptSchemesQueryString + ":" + term.concept));
      const firstLocalMap: Map<string, boolean> = new Map();

      for (const resultItem of cachedTerms) {
        if (expandableCache.has(conceptSchemesQueryString + ":" + resultItem.concept)) {
          firstLocalMap.set(
            conceptSchemesQueryString + ":" + resultItem.concept,
            expandableCache.get(conceptSchemesQueryString + ":" + resultItem.concept)!,
          );
        }
      }

      setDataExpandable(firstLocalMap);

      uncachedTerms.length &&
        fetch(sparqlUrl, {
          credentials: "same-origin",
          method: "POST",
          headers: { Accept: "application/json" },
          body: new URLSearchParams({
            account: dataset?.owner.accountName,
            dataset: dataset?.name,
            queryString: sparqlFetchExpandable(
              uncachedTerms.map((term) => `<${term.concept}>`).join(" "),
              selectedSchemes,
            ),
          }),
        })
          .then((response) => response.json())
          .then((data: { concept: string; isExpandable: string }[]) => {
            const secondLocalMap: Map<string, boolean> = new Map(firstLocalMap.entries());
            for (const resultItem of data) {
              expandableCache.set(
                conceptSchemesQueryString + ":" + resultItem.concept,
                resultItem.isExpandable === "true",
              );
              secondLocalMap.set(
                conceptSchemesQueryString + ":" + resultItem.concept,
                resultItem.isExpandable === "true",
              );
            }
            setDataExpandable(secondLocalMap);
          })
          .catch(() => {});
    },
    100,
  );

  React.useEffect(() => {
    fetchExpandable(terms, selectedSchemes, conceptSchemesQueryString);
  }, [fetchExpandable, conceptSchemesQueryString, selectedSchemes, terms]);

  return (
    <div className={styles.innerTreeChild} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
      {virtualItems.map((virtualItem) => {
        const item = data[virtualItem.index];
        const isExpandable = dataExpandable.get(conceptSchemesQueryString + ":" + item.concept);
        return (
          <TreeTopItem
            virtualizer={rowVirtualizer}
            key={virtualItem.key}
            virtualItem={virtualItem}
            item={item}
            isExpandable={isExpandable}
          />
        );
      })}
    </div>
  );
};
