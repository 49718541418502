import { Breadcrumbs, Chip } from "@mui/material";
import { split } from "lodash-es";
import * as React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { FontAwesomeIcon } from "#components/index.ts";
import { getHierarchy, Item } from "#components/Tree/index.tsx";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import * as styles from "./styles/index.scss";

const factory = factories.compliant;

const sparqlFetchCurrentResourceClassPaths = (resource: string) => `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
select * where {
  bind(${termToString(factory.namedNode(resource))} as ?currentClass)
  ?currentClass rdfs:subClassOf* ?id.
  optional {
    ?id rdfs:subClassOf ?parent .
  }
  optional{
    ?id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label
  }
}
`;

const ClassBreadcrumbs: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();
  const location = useLocation();
  const applyPrefixes = useApplyPrefixes();
  const prefixes = useDatasetPrefixes();
  const [rawBreadcrumbs, setRawBreadcrumbs] = React.useState<{ [key: string]: boolean }>();

  const { data, error, loading } = useSparql<Item[]>(sparqlFetchCurrentResourceClassPaths(currentClass));

  React.useEffect(() => {
    if (currentClass && data) {
      const modifiedData = data.map((d) => {
        if (d.parent === null) d.parent = "tree:root";
        return d;
      });
      const { selectedKeys } = getHierarchy(modifiedData, currentClass, prefixes);
      setRawBreadcrumbs(selectedKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, data]);

  if (loading || error || !data || data.length === 0 || !rawBreadcrumbs) return null;

  const breadcrumbs = Object.keys(rawBreadcrumbs)
    .map((key) => split(key, ">"))
    .map((crumbs) => {
      return crumbs.map((c) => {
        if (c !== "tree:root") {
          return data.find((item) => item.id === c);
        }
      });
    });

  return (
    <>
      {breadcrumbs.map((crumbs, index) => {
        if (crumbs.length <= 2) return null;
        return (
          <Breadcrumbs
            separator={<FontAwesomeIcon icon={["fas", "caret-right"]} />}
            maxItems={6}
            className={styles.breadcrumbs}
            key={index}
          >
            {crumbs.map((crumb) => {
              if (crumb && currentClass !== crumb.id) {
                return (
                  <Link
                    to={{
                      pathname: location.pathname,
                      search: stringifyQuery({ resource: crumb.id }),
                    }}
                    key={crumb.id}
                  >
                    {crumb.label || applyPrefixes(crumb.id)}
                  </Link>
                );
              } else if (crumb) {
                return <Chip label={crumb.label || applyPrefixes(crumb.id)} size="small" key={crumb.id} />;
              }
            })}
          </Breadcrumbs>
        );
      })}
    </>
  );
};

export default ClassBreadcrumbs;
