import { generateColor } from "@marko19907/string-to-color";
import { Chip, CircularProgress } from "@mui/material";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocalNameInfo } from "@triply/utils/prefixUtils.js";
import { stringifyQuery } from "@triply/utils-private";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { COLOR_GENERATE_OPTIONS, SparqlTermResult } from "../SkosTree";
import { useCachedSparql } from "../useCachedSparql";
import { expandedContext, ExpandedMap } from "./ExpandedContext";
import { sparqlFetchTreeChild } from "./queries";
import { skosTreeContext } from "./SkosTreeContext";
import * as styles from "../style.scss";

type TreeItemProps = {
  item: SparqlTermResult;
  isExpandable?: boolean;
  parents?: string[];
  reportMeasurement: () => void;
};

const getExpandedState = (expandedMap: ExpandedMap, breadcrumb: string[]): boolean => {
  if (!breadcrumb.length || !Object.keys(expandedMap).length) return false;
  let pointer: any = expandedMap;

  for (const [index, iri] of breadcrumb.entries()) {
    if (index !== breadcrumb.length - 1) {
      if (!pointer[iri]) return false;
      pointer = pointer[iri];
    } else {
      return !!pointer[iri];
    }
  }

  return false;
};

export function TreeItem({ item, isExpandable, parents: parentScope, reportMeasurement }: TreeItemProps) {
  const { schemes } = React.useContext(skosTreeContext);
  const resource = useCurrentResource();
  const itemScope = React.useMemo(() => [...(parentScope ?? []), item.concept], [parentScope, item.concept]);

  const scheme = schemes.find((scheme) => scheme.conceptScheme === item.scheme);
  const schemeColor = scheme?.conceptScheme ? generateColor(scheme?.conceptScheme, COLOR_GENERATE_OPTIONS) : "#fff";
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const { expandedMap, activeMap, collapse, expand } = React.useContext(expandedContext);

  const selectedSchemes = conceptSchemesQueryString.split(",").filter(Boolean);
  const location = useLocation();

  const [expanded, setExpanded] = React.useState(
    getExpandedState(expandedMap, [conceptSchemesQueryString, ...itemScope]),
  );

  React.useEffect(() => {
    setExpanded(getExpandedState(expandedMap, [conceptSchemesQueryString, ...itemScope]));
  }, [expandedMap, conceptSchemesQueryString, itemScope, item.concept]);

  const { data: children, loading } = useCachedSparql<SparqlTermResult[]>(
    expanded && sparqlFetchTreeChild(item.concept, selectedSchemes),
  );

  React.useEffect(() => {
    // after rendering the children, we know how much height it takes visually, trigger the parent to recalculate the real height.
    requestAnimationFrame(reportMeasurement);
  }, [children, reportMeasurement, expanded]);

  const dataset = useCurrentDataset();

  const isInTrail = getExpandedState(activeMap, [conceptSchemesQueryString, ...itemScope]);

  const expandHandleClick = () => {
    const nextExpandedState = !expanded;
    setExpanded(nextExpandedState);
    if (nextExpandedState === true) expand([conceptSchemesQueryString, ...itemScope]);
    else collapse([conceptSchemesQueryString, ...itemScope]);
  };

  if (!dataset) return null;

  return (
    <>
      <div
        className={`${styles.treeItem} ${isInTrail ? styles.treeItemInTrail : ""} ${item.concept === resource ? styles.treeItemActive : ""}`}
        data-concept-iri={item.concept}
      >
        <div role="button" tabIndex={-1} className={styles.expandButton} onClick={expandHandleClick}>
          {isExpandable ? (
            loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <FontAwesomeIcon icon={expanded ? "chevron-down" : "chevron-right"} />
            )
          ) : (
            <FontAwesomeIcon icon={"period"} />
          )}
        </div>
        <Link
          className={styles.link}
          title={item.conceptLabel}
          onDragStart={(e) => e.dataTransfer.setData("text/plain", item.concept)}
          to={{
            pathname: location.pathname,
            search: stringifyQuery({ ...search, resource: item.concept }),
          }}
        >
          {item.conceptLabel}
        </Link>
        {scheme && (
          <Chip
            className={styles.schemeLabel}
            size="small"
            title={scheme.label}
            style={{ background: schemeColor }}
            label={scheme.label || getLocalNameInfo(scheme.conceptScheme).localName}
          />
        )}
      </div>
      {children?.length && isExpandable && expanded ? (
        <div className={styles.treeItemChildren}>
          {children.map((childItem) => (
            <TreeItem
              reportMeasurement={reportMeasurement}
              parents={itemScope}
              isExpandable={childItem.isExpandable === "true"}
              key={[parentScope || [], childItem.concept].join(",")}
              item={childItem}
            />
          ))}
        </div>
      ) : null}
    </>
  );
}
export default React.memo(TreeItem);
