import { Alert, Skeleton } from "@mui/material";
import { toPairs } from "lodash-es";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import useSparql from "#helpers/hooks/useSparql.ts";
import PropertyGroup from "../PropertyGroup";
import PropertyShape from "./PropertyShape";
import { FormValues } from "./Types";

const factory = factories.compliant;
const NO_GROUP = "none";

const NodeShape: React.FC<{ classIri: string; namePrefix: "properties" }> = ({ classIri, namePrefix }) => {
  const { data, error, loading } = useSparql<{
    groups:
      | Array<{
          groupIri: string;
          groupName: string;
          properties: Array<{
            propertyShape: string;
            path: string;
          }>;
        }>
      | undefined;
  }>(
    classIri &&
      `
    # NodeShape
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select
      ?groups_groupIri
      (sample(?groups_groupName_u) as ?groups_groupName)
      ?groups_properties_propertyShape
      ?groups_properties_path
    where {
      ${termToString(factory.namedNode(classIri))} rdfs:subClassOf*/^sh:targetClass ?nodeShape .
      ?nodeShape sh:property ?groups_properties_propertyShape .
      ?groups_properties_propertyShape sh:path ?groups_properties_path .
      optional {
        ?groups_properties_propertyShape sh:order ?groups_properties_order .
      }
      optional {
        ?groups_properties_propertyShape sh:group ?groupIri .
        ?groupIri rdfs:label ?groups_groupName_u ;
               sh:order ?groups_groupOrder .

      }
      bind(coalesce(?groupIri, "${NO_GROUP}") as ?groups_groupIri)
    }
    group by
      ?groups_groupIri
      ?groups_properties_propertyShape
      ?groups_properties_path
      ?groups_groupOrder
      ?groups_properties_order

    order by (!bound(?groups_groupOrder)) coalesce(?groups_groupOrder, -100000) (!bound(?groups_properties_order)) ?groups_properties_order
    `,
    {
      singularizeVariables: {
        "": true,
      },
    },
  );

  const { getValues } = useFormContext<FormValues>();

  if (loading) return <Skeleton />;

  if (error) return <Alert severity="error">Failed to load.</Alert>;

  if (!data) {
    return null;
  }

  const dataModelProperties = (data.groups || []).flatMap((group) => {
    return group.properties.map((property) => property.path);
  });

  const values = getValues(namePrefix);

  const customProperties = toPairs(values).filter(
    (val) => val[1]?.length > 0 && !dataModelProperties.includes(val[0].replace(/ /g, ".")),
  );

  return (
    <>
      {(data.groups || []).map((group) => {
        const propertyShapes = (
          <div key={"div" + group.groupIri} className="flex column g-7">
            {group.properties.map((property) => {
              return (
                <PropertyShape
                  key={group.groupIri + property.propertyShape}
                  propertyShape={property.propertyShape}
                  propertyPath={property.path}
                  name={(namePrefix + "." + property.path.replace(/\./g, " ")) as `properties.${string}`}
                />
              );
            })}
          </div>
        );

        if (group.groupIri === NO_GROUP) {
          return propertyShapes;
        }

        return (
          <PropertyGroup key={"group" + group.groupIri} groupName={group.groupName}>
            <div className="px-3">{propertyShapes}</div>
          </PropertyGroup>
        );
      })}
      {customProperties.length > 0 && (
        <PropertyGroup groupName="Properties outside the data model">
          <div className="flex column g-7 px-3">
            {customProperties.map((customProperty) => {
              return (
                <PropertyShape
                  key={customProperty[0]}
                  propertyPath={customProperty[0].replace(/ /g, ".")}
                  name={`${namePrefix}.${customProperty[0]}`}
                />
              );
            })}
          </div>
        </PropertyGroup>
      )}
    </>
  );
};

export default NodeShape;
