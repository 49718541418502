import { generateColor } from "@marko19907/string-to-color";
import { Autocomplete, Chip, CircularProgress, InputAdornment, TextField } from "@mui/material";
import getClassname from "classnames";
import { set } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { getLocalNameInfo } from "@triply/utils/prefixUtils.js";
import { FontAwesomeIcon } from "#components/index.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { setLastSkosConceptHierarchy } from "#reducers/datasets.ts";
import { SearchField } from "../Filters";
import { COLOR_GENERATE_OPTIONS } from "../SkosTree";
import { skosTreeContext } from "./SkosTreeContext";
import * as styles from "../style.scss";

function fixInputParams<T extends React.InputHTMLAttributes<HTMLInputElement> & { ref: React.Ref<HTMLInputElement> }>(
  params: T,
) {
  return {
    ...params,
    // The MUI types do not match
    width: params?.width?.toString(),
    height: params?.height?.toString(),
  };
}

export type Scheme = { conceptScheme: string; label: string };

const SchemeSelector: React.FC<{}> = () => {
  const search = useCurrentSearch();
  const selectedSchemes = ((search.conceptScheme as string) || "").split(",").filter(Boolean);
  const history = useHistory();
  const { schemes: allSchemes, hierarchy, loading } = React.useContext(skosTreeContext);
  const dispatch = useDispatch();
  const ds = useCurrentDataset();
  const schemeOptions: string[] = React.useMemo(() => {
    if (!allSchemes || !hierarchy) return [];
    let schemes: string[] = [];

    if (selectedSchemes.length === 0) {
      schemes = allSchemes.map((i) => i.conceptScheme);
    } else {
      schemes = hierarchy
        .filter((item) => item.parentConceptScheme === selectedSchemes.at(-1))
        .map((item) => item.childConceptScheme);
    }

    schemes.sort((a, b) => {
      const aIndex = allSchemes.indexOf(allSchemes.find((scheme) => scheme.conceptScheme === a)!);
      const bIndex = allSchemes.indexOf(allSchemes.find((scheme) => scheme.conceptScheme === b)!);
      return aIndex - bIndex;
    });

    return schemes;
  }, [allSchemes, hierarchy, selectedSchemes]);

  return (
    <>
      <div className={getClassname(styles.skosSchemeSelector)}>
        <Autocomplete
          multiple
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const scheme = allSchemes.find((i) => i.conceptScheme === option);
              const schemeColor = scheme && generateColor(scheme.conceptScheme, COLOR_GENERATE_OPTIONS);
              const { ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  {...tagProps}
                  variant="filled"
                  label={scheme?.label || (scheme && getLocalNameInfo(scheme?.conceptScheme).localName) || "Loading..."}
                  deleteIcon={<FontAwesomeIcon size="2xs" icon="xmark" />}
                  key={option}
                  className={getClassname(styles.schemeChip, index === selectedSchemes.length - 1 ? "end" : "")}
                  style={{ background: schemeColor, "--color": schemeColor } as React.CSSProperties}
                />
              );
            })
          }
          noOptionsText={""}
          slotProps={{
            popper: {
              className: styles.schemeSelectorPopper,
            },
          }}
          getOptionLabel={(item) => allSchemes.find((i) => i.conceptScheme === item)?.label ?? "Not found"}
          id="schemes"
          disabled={allSchemes?.length === 0}
          value={selectedSchemes}
          onChange={(_event, values) => {
            // When removing a scheme, also remove all schemes after it
            if (values.length < selectedSchemes.length) {
              const removedItem = selectedSchemes.find((item) => !values.includes(item));
              if (removedItem) {
                const removedItemIndex = selectedSchemes.indexOf(removedItem);
                values = selectedSchemes.slice(0, removedItemIndex);
              }
            }

            set(search, "conceptScheme", values.join(","));
            dispatch(setLastSkosConceptHierarchy(ds!.id, values.join(","))); // Ds should be defined, otherwise this component shouldn't be shown at all
            history.push({ search: stringifyQuery(search) });
          }}
          options={schemeOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Concept scheme(s)"
              helperText={`Concept schemes can extend each other, you can select a hierarchy here.`}
              placeholder={schemeOptions.length ? "Select concept scheme" : ""}
              inputProps={{
                ...fixInputParams(params.inputProps),
                style: {
                  minWidth: "1px",
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={16} />
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      </div>
      {selectedSchemes.length ? <SearchField selectedSchemes={selectedSchemes} /> : null}
    </>
  );
};
export default React.memo(SchemeSelector);
