import { fromPairs } from "lodash-es";
import * as React from "react";
import { Converter } from "sparqljson-to-tree";
import { ClassInfo } from "./Types.ts";
import useSparql from "./useSparql";

const converter = new Converter({ materializeRdfJsTerms: true });

const classesQuery = `
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix dash: <http://datashapes.org/dash#>

select
  ?id
  (sample(?stem_t) as ?stem)
  (sample(?label_t) as ?label)
  (sample(?description_t) as ?description)
{

  ?nodeShape sh:targetClass ?class .
  ?id rdfs:subClassOf* ?class .

  filter not exists {
    ?nodeShape dash:abstract true .
  }

  optional {
    ?nodeShape sh:targetClass ?id .
    ?nodeShape dash:stem ?stem_t .
  }

  optional {
    ?id rdfs:label|skos:prefLabel ?label_t .
  }
  optional {
    ?id rdfs:comment|skos:definition ?description_t
  }
  optional {
    ?class ^sh:targetClass/sh:order ?order
  }
}
group by
?id order by (coalesce(sample(?order),1000000))
`;

const useClasses = () => {
  const sparql = useSparql();
  const [classes, setClasses] = React.useState<ClassInfo[]>();

  React.useEffect(() => {
    sparql(classesQuery)
      .then((results) => {
        const json = converter.sparqlJsonResultsToTree(results, {
          singularizeVariables: fromPairs(results.head!.vars.map((v) => [v, true])),
        });
        // console.log(json);
        setClasses(json);
      })
      .catch(() => {});
  }, [sparql]);

  return classes;
};

export default useClasses;
