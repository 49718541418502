import { createContext, ReactNode, useState } from "react";
import * as React from "react";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { useCachedSparql } from "../useCachedSparql";
import { sparqlFetchCurrentResource } from "./queries";

const errorMessageForInvalidUsage = "Component must be a child of ExpandedContextProvider";
export const expandedContext = createContext<{
  expandedMap: ExpandedMap;
  activeMap: ExpandedMap;
  setActive: (trail: string[]) => void;
  collapse: (trail: string[]) => void;
  expand: (trail: string[]) => void;
}>({
  expandedMap: {},
  activeMap: {},
  setActive: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
  collapse: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
  expand: () => {
    throw new Error(errorMessageForInvalidUsage);
  },
});

export interface ExpandedMap {
  [filter: string]: {};
}

type SparqlBreadcrumbResult = { begin: string; midLeft: string; midRight: string; end: string };

export const useResourceBreadcrumbs = () => {
  const resource = useCurrentResource();
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const conceptSchemes = conceptSchemesQueryString.split(",").filter(Boolean);

  const { data: breadcrumbParts = [] } = useCachedSparql<SparqlBreadcrumbResult[]>(
    sparqlFetchCurrentResource(resource, conceptSchemes),
  );

  const processedBreadcrumbs = React.useMemo(() => {
    let filteredBreadcrumbParts = breadcrumbParts;
    const breadcrumbMap: Map<string, SparqlBreadcrumbResult[]> = new Map();

    // The data may contain paths that are incomplete because of the filtering. We must filter out paths that do not end with our resource.
    // However there can also be multiple paths with the same begin and end. For that reason we must filter out trails that do not have a follow up node.

    const findBrokenLink = () => {
      const midRightItems = filteredBreadcrumbParts.map((part) => part.midRight);
      return filteredBreadcrumbParts.find((part) => !midRightItems.includes(part.midLeft) && part.midLeft !== part.end);
    };

    let brokenLink = findBrokenLink();
    while (brokenLink) {
      filteredBreadcrumbParts = filteredBreadcrumbParts.filter((part) => part !== brokenLink);
      brokenLink = findBrokenLink();
    }

    for (const breadcrumbPart of filteredBreadcrumbParts) {
      const trail = `${breadcrumbPart.begin}:${breadcrumbPart.end}`;
      if (!breadcrumbMap.has(trail)) breadcrumbMap.set(trail, []);
      const trailBreadcrumbs = breadcrumbMap.get(trail);
      trailBreadcrumbs!.push(breadcrumbPart);
    }

    return [...breadcrumbMap.values()].map((breadcrumbParts) => {
      return [breadcrumbParts[0].begin, ...breadcrumbParts.reverse().map((i) => i.midLeft)];
    });
  }, [breadcrumbParts]);

  return processedBreadcrumbs;
};

const setInitialExpandedMap = (breadcrumbs: string[][], filters: string, expandedMap: ExpandedMap = {}) => {
  const returnExpandedMap = { ...expandedMap };

  for (const breadcrumb of breadcrumbs) {
    let pointer = returnExpandedMap;
    // The first level is the filter
    if (!pointer[filters]) pointer[filters] = {};
    pointer = pointer[filters];

    for (const [index, iri] of breadcrumb.entries()) {
      // All items except the last
      if (index !== breadcrumb.length - 1) {
        if (!pointer[iri]) pointer[iri] = {};
        pointer = pointer[iri];
      }
      // Last item
      else {
        pointer[iri] = {};
      }
    }
  }

  return returnExpandedMap;
};

const setTrail = (trail: string[], object: any) => {
  let pointer: any = object;
  for (const iri of trail) {
    if (!pointer[iri]) pointer[iri] = {};
    pointer = pointer[iri];
  }

  return object;
};

const collapseTrailEnd = (trail: string[], object: any) => {
  let pointer: any = object;
  for (const [index, iri] of trail.entries()) {
    // When we are in the last one we need to delete the item. Existence of the object is read as expanded state.
    if (index === trail.length - 1) {
      delete pointer[iri];
    }

    if (!pointer[iri]) return object;
    pointer = pointer[iri];
  }

  return object;
};

const ExpandedContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";

  const breadcrumbs = useResourceBreadcrumbs();

  const resource = useCurrentResource();

  const [expandedMap, setExpandedMap] = useState<ExpandedMap>(
    setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString),
  );

  const [activeMap, setActiveMap] = useState<ExpandedMap>(
    setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString),
  );

  const setActive = (trail: string[]) => {
    // General expanding
    setExpandedMap(setTrail(trail, { ...expandedMap }));
    // The active trail
    setActiveMap(setTrail(trail, {}));
  };

  const collapse = (trail: string[]) => {
    setExpandedMap(collapseTrailEnd(trail, { ...expandedMap }));
  };

  const expand = (trail: string[]) => {
    setExpandedMap(setTrail(trail, { ...expandedMap }));
  };

  const breadcrumbsString = breadcrumbs.flat().join(",");
  React.useEffect(() => {
    setActiveMap(setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString, {}));
    setExpandedMap(setInitialExpandedMap(breadcrumbs, conceptSchemesQueryString, {}));
    // useResourceBreadcrumbs is not yet atomic.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, breadcrumbsString, conceptSchemesQueryString]);

  return (
    <expandedContext.Provider value={{ expandedMap, activeMap, setActive, collapse, expand }}>
      {children}
    </expandedContext.Provider>
  );
};

export default ExpandedContextProvider;
