import { produce } from "immer";
import { fromPairs, groupBy } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { Models, Routes } from "@triply/utils";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";

export const factory = factories.compliant;

const converter = new Converter({ materializeRdfJsTerms: true });

export const LocalActions = {
  GET_EDITOR_DESCRIPTION: "triply/resourceEditorDescriptions/GET_DESCRIPTION",
  GET_EDITOR_DESCRIPTION_SUCCESS: "triply/resourceEditorDescriptions/GET_DESCRIPTION_SUCCESS",
  GET_EDITOR_DESCRIPTION_FAIL: "triply/resourceEditorDescriptions/GET_DESCRIPTION_FAIL",
  GET_EDITOR_DESCRIPTION_CLASS: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION",
  GET_EDITOR_DESCRIPTION_CLASS_SUCCESS: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION_SUCCESS",
  GET_EDITOR_DESCRIPTION_CLASS_FAIL: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION_FAIL",
  REMOVE_EDITOR_DESCRIPTION: "triply/resourceEditorDescriptions/REMOVE_EDITOR_DESCRIPTION",
  CLEAR_CLASS_DESCRIPTIONS: "triply/resourceEditorDescriptions/CLEAR_CLASS_DESCRIPTIONS",
} as const;

type GET_DESCRIPTION = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_EDITOR_DESCRIPTION,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_SUCCESS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_FAIL,
    ];
    dataset: Dataset;
    resource: string;
  },
  Routes.datasets._account._dataset.sparql.Post
>;

type GET_CLASS_DESCRIPTION = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS_FAIL,
    ];
    dataset: Dataset;
    classIri: string;
  },
  Routes.datasets._account._dataset.sparql.Post
>;
type REMOVE_EDITOR_DESCRIPTION = GlobalAction<{
  type: typeof LocalActions.REMOVE_EDITOR_DESCRIPTION;
  datasetId: string;
  resource: string;
}>;

type CLEAR_CLASS_DESCRIPTIONS = GlobalAction<{
  type: typeof LocalActions.CLEAR_CLASS_DESCRIPTIONS;
  datasetId: string;
}>;

export type LocalAction =
  | GET_DESCRIPTION
  | GET_CLASS_DESCRIPTION
  | REMOVE_EDITOR_DESCRIPTION
  | CLEAR_CLASS_DESCRIPTIONS;

export type Triple = Models.QueryResult;
export type Statements = Triple[];

export type NodeKind = "IRI" | "Literal" | "NestedNode";
export interface ResourceEditorDescriptions {
  nodeKind: NodeKind;
  value: string;
  type: string;
  typeLabel?: string;
  valueLabel?: string;
  editor?: string;
  editedAt?: Date;
  status?: string;
  note?: string;
  creator?: string;
  createdAt?: Date;
  firstAction?: string;
  properties?: {
    [propertyIri: string]: Property[];
  };
}

interface BaseProperty {
  nodeKind: NodeKind;
  value: string;
  predicate: string;
}
interface LiteralProperty extends BaseProperty {
  nodeKind: "Literal";
  datatype?: string;
  language?: string;
}
interface IRIProperty extends BaseProperty {
  nodeKind: "IRI";
  valueLabel?: string;
}
export interface NestedNodeProperty extends BaseProperty {
  nodeKind: "NestedNode";
  properties: { [propertyIri: string]: Property[] };
  type?: string;
}

export interface ClassProperty {
  propertyShape: string;
  propertyShapeLabel: string;
  path: string;
}
export interface ResourceEditorClass {
  groupIri?: string;
  groupName: string;
  groupOrder?: number;
  properties?: Array<ClassProperty>;
}

export type Property = LiteralProperty | IRIProperty | NestedNodeProperty;

export interface State {
  [datasetId: string]: {
    resources: { [propertyId: string]: ResourceEditorDescriptions };
    classShapes: { [classIri: string]: { groups: ResourceEditorClass[]; shapeIri: string } };
  };
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_EDITOR_DESCRIPTION_SUCCESS:
        let instanceResult = converter.sparqlJsonResultsToTree(action.result, {
          singularizeVariables: {
            "": true,
            ...fromPairs(action.result.head!.vars.map((v: string) => [v, true])),
          },
        });
        if (!instanceResult.nodeKind) return;
        instanceResult.properties = transformProperties(instanceResult.properties);
        if (!draftState[action.dataset.id]) draftState[action.dataset.id] = { classShapes: {}, resources: {} };
        draftState[action.dataset.id].resources[action.resource] = instanceResult;
        return;
      case Actions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS:
        let classResult = converter.sparqlJsonResultsToTree(action.result, {
          singularizeVariables: {
            "": true,
            ...fromPairs(action.result.head!.vars.map((v: string) => [v, true])),
          },
        });
        if (!draftState[action.dataset.id]) draftState[action.dataset.id] = { classShapes: {}, resources: {} };
        if (!classResult.shapeIri) return;
        draftState[action.dataset.id].classShapes[action.classIri] = classResult;
        return;
      case Actions.REMOVE_EDITOR_DESCRIPTION:
        delete draftState[action.datasetId].resources[action.resource];
        return;
      case Actions.CLEAR_CLASS_DESCRIPTIONS:
        if (draftState[action.datasetId]) draftState[action.datasetId].classShapes = {};
        return;
    }
  },
  <State>{},
);

interface GetDescriptionOptions {
  dataset: Dataset;
  resource: string;
}
interface GetClassOptions {
  dataset: Dataset;
  classIri: string;
}

export function getDescription(opts: GetDescriptionOptions): BeforeDispatch<GET_DESCRIPTION> {
  const { dataset, resource } = opts;
  return {
    types: [
      Actions.GET_EDITOR_DESCRIPTION,
      Actions.GET_EDITOR_DESCRIPTION_SUCCESS,
      Actions.GET_EDITOR_DESCRIPTION_FAIL,
    ],
    promise: (client) => {
      return client.req({
        pathname: `/_console/sparql`,
        body: { queryString: valuesQuery(resource), dataset: dataset.name, account: dataset.owner.accountName } as any,

        method: "post",
        accept: "application/sparql-results+json",
      });
    },
    dataset: dataset,
    resource: resource,
  };
}
export function getClassDescription(opts: GetClassOptions): BeforeDispatch<GET_CLASS_DESCRIPTION> {
  const { dataset, classIri } = opts;
  return {
    types: [
      Actions.GET_EDITOR_DESCRIPTION_CLASS,
      Actions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS,
      Actions.GET_EDITOR_DESCRIPTION_CLASS_FAIL,
    ],
    promise: (client) => {
      return client.req({
        pathname: `/_console/sparql`,
        body: { queryString: classQuery(classIri), dataset: dataset.name, account: dataset.owner.accountName } as any,

        method: "post",
        accept: "application/sparql-results+json",
      });
    },
    dataset: dataset,
    classIri: classIri,
  };
}
export function removeEditorDescription(
  datasetId: string,
  resource: string,
): BeforeDispatch<REMOVE_EDITOR_DESCRIPTION> {
  return {
    type: Actions.REMOVE_EDITOR_DESCRIPTION,
    datasetId: datasetId,
    resource: resource,
  };
}
export function clearClassDescriptions(datasetId: string): BeforeDispatch<CLEAR_CLASS_DESCRIPTIONS> {
  return {
    type: Actions.CLEAR_CLASS_DESCRIPTIONS,
    datasetId: datasetId,
  };
}

type DescriptionFromStateOptions = GetDescriptionOptions & {
  state: State | undefined;
};
type ClassFromStateOptions = GetClassOptions & {
  state: State | undefined;
};

export function descriptionIsLoadedFor(opts: DescriptionFromStateOptions) {
  const { dataset, resource } = opts;
  if (!resource) return true;
  const resourceDescription = opts.state?.[dataset.id]?.resources?.[opts.resource];
  return !!resourceDescription;
}
export function classIsLoadedFore(opts: ClassFromStateOptions) {
  const { dataset, classIri } = opts;
  if (!classIri) return true;
  const resourceDescription = opts.state?.[dataset.id]?.classShapes?.[opts.classIri];
  return !!resourceDescription;
}

export function getDescriptionFor(opts: DescriptionFromStateOptions) {
  const { dataset, resource } = opts;
  if (descriptionIsLoadedFor(opts)) {
    return opts.state?.[dataset.id].resources[resource];
  }
}
export function getStateDescriptionFor(opts: ClassFromStateOptions) {
  const { dataset, classIri } = opts;
  if (classIsLoadedFore(opts)) {
    return opts.state?.[dataset.id].classShapes[classIri];
  }
}

const valuesQuery = (resource: string) => {
  const resourceIri = termToString(factory.namedNode(resource));

  // Note reordering is set to false due to #9902
  return `
# resourceEditorDescriptions valuesQuery
#! reorder: false

prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>
prefix skosXl: <http://www.w3.org/2008/05/skos-xl#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix dash: <http://datashapes.org/dash#>

select
  ?nodeKind
  ?value
  ?type
  (sample(?typeLabel_u) as ?typeLabel)
  (sample(?valueLabel_u) as ?valueLabel)
  ?editor
  ?editedAt
  ?status
  ?note
  ?creator
  ?createdAt
  ?firstAction
  ?properties_nodeKind
  ?properties_value
  ?properties_type
  ?properties_predicate
  ?properties_datatype
  ?properties_language
  (sample(?properties_valueLabel_u) as ?properties_valueLabel )
  ?properties_properties_nodeKind
  ?properties_properties_value
  ?properties_properties_predicate
  ?properties_properties_datatype
  ?properties_properties_language
  (sample(?properties_properties_valueLabel_u) as ?properties_properties_valueLabel)

where {
  bind(${resourceIri} as ?value)
  ?value a ?type .
  optional {
    ?type rdfs:label ?typeLabel_u
  }
  optional {
    ?type skos:prefLabel ?typeLabel_u
  }
  optional {
    ?type skosXl:prefLabel/skosXl:literalForm ?typeLabel_u
  }
  optional {
    ?value rdfs:label ?valueLabel_u
  }
  optional {
    ?value skos:prefLabel ?valueLabel_u
  }
  optional {
    ?value skosXl:prefLabel/skosXl:literalForm ?valueLabel_u
  }
  optional {
    ?value skosXl:literalForm ?valueLabel_u
  }
  bind("IRI" as ?nodeKind)
  # Get history items
  optional {
    select
      ?editor ?editedAt ?status ?note where {
        bind(${resourceIri} as ?value)
        ?history meta:product ?value;
                 meta:actor ?editor;
                 meta:time ?editedAt;
        optional {
          ?history meta:toStatus ?status
        }
        optional {
          ?history meta:editorialNote ?note
        }
      } order by desc(?editedAt) limit 1
  }
  optional {
    select
      ?creator ?createdAt ?firstAction where {
        bind(${resourceIri} as ?value)
        ?history meta:product ?value;
                 meta:actor ?creator;
                 meta:time ?createdAt;
                 meta:action ?firstAction
        filter not exists {
          ?history meta:fromStatus []
        }
      } order by asc(?createdAt) limit 1
  }
  optional {
    ?value ?properties_predicate ?properties_value.
    filter(?properties_predicate != rdf:type)
    optional {
      [] sh:targetClass/^rdfs:subClassOf* ?type ;
         sh:property [
                       sh:path ?properties_predicate ;
                       dash:viewer dash:DetailsViewer
                       ]
      bind(true as ?properties_nestedNode)
    }
    bind(if(isiri(?properties_value), if(bound(?properties_nestedNode), "NestedNode", "IRI"), "Literal") as ?properties_nodeKind)

    optional {
      bind("IRI" as ?properties_nodeKind) .
      {
        select ?properties_value (sample(?properties_valueLabel_toAgg) as ?properties_valueLabel_u) {
          {
            ?properties_value rdfs:label ?properties_valueLabel_toAgg
          }
          union {
            ?properties_value skos:prefLabel ?properties_valueLabel_toAgg
          }
          union {
            ?properties_value skosXl:prefLabel/skosXl:literalForm ?properties_valueLabel_toAgg
          }
          union {
            ?properties_value skosXl:literalForm ?properties_valueLabel_toAgg
          }
        } group by ?properties_value
      }

    }
    optional {
      bind("Literal" as ?properties_nodeKind)
      bind(datatype(?properties_value) as ?properties_datatype)
      bind(lang(?properties_value) as ?properties_language)
    }
    optional {
      bind("NestedNode" as ?properties_nodeKind)
      ?properties_value a ?properties_type .
      ?properties_value ?properties_properties_predicate ?properties_properties_value
      filter(?properties_properties_predicate != rdf:type)
      bind(if(isiri(?properties_properties_value), "IRI", "Literal") as ?properties_properties_nodeKind)
      optional {
        bind("Literal" as ?properties_properties_nodeKind)
        bind(datatype(?properties_properties_value) as ?properties_properties_datatype)
        bind(lang(?properties_properties_value) as ?properties_properties_language)
      }

      optional {
        bind("IRI" as ?properties_properties_nodeKind) .
        {
          select ?properties_properties_value (sample(?properties_properties_valueLabel_toAgg) as ?properties_properties_valueLabel_u) {
            {
              ?properties_properties_value rdfs:label ?properties_properties_valueLabel_toAgg
            }
            union {
              ?properties_properties_value skos:prefLabel ?properties_properties_valueLabel_toAgg
            }
            union {
              ?properties_properties_value skosXl:prefLabel/skosXl:literalForm ?properties_properties_valueLabel_toAgg
            }
            union {
              ?properties_properties_value skosXl:literalForm ?properties_properties_valueLabel_toAgg
            }
          } group by ?properties_properties_value
        }
      }
    }
  }
}
group by
  ?nodeKind
  ?value
  ?type
  ?editor
  ?editedAt
  ?status
  ?note
  ?creator
  ?createdAt
  ?firstAction
  ?properties_nodeKind
  ?properties_value
  ?properties_type
  ?properties_predicate
  ?properties_datatype
  ?properties_language
  ?properties_properties_nodeKind
  ?properties_properties_value
  ?properties_properties_predicate
  ?properties_properties_datatype
  ?properties_properties_language

limit 1000
`;
};

export const NO_GROUP = "none";
const classQuery = (classIri: string) => `
    # resourceEditorDescriptions classQuery
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select
      ?shapeIri
      ?groups_groupIri
      (sample(?groups_groupName_u) as ?groups_groupName)
      ?groups_properties_propertyShape
      (sample(?groups_properties_propertyShapeLabel_u) as ?groups_properties_propertyShapeLabel)
      ?groups_properties_path
    where {
      bind(${termToString(factory.namedNode(classIri))} as ?currentClass)
      ?shapeIri sh:targetClass ?currentClass.
      ?currentClass rdfs:subClassOf*/^sh:targetClass ?nodeShape .
      ?nodeShape sh:property ?groups_properties_propertyShape .
      ?groups_properties_propertyShape sh:path ?groups_properties_path .
      optional {
        ?groups_properties_propertyShape sh:name ?groups_properties_propertyShapeLabel_u.
      }
      optional {
        ?groups_properties_propertyShape sh:order ?groups_properties_order .
      }
      optional {
        ?groups_properties_propertyShape sh:group ?groupIri .
        ?groupIri rdfs:label ?groups_groupName_u ;
                  sh:order ?groups_groupOrder .

      }
      bind(coalesce(?groupIri, "${NO_GROUP}") as ?groups_groupIri)
    }
    group by
      ?shapeIri
      ?groups_groupIri
      ?groups_properties_propertyShape
      ?groups_properties_path
      ?groups_groupOrder
      ?groups_properties_order
    order by (!bound(?groups_groupOrder)) coalesce(?groups_groupOrder, -1000000) (!bound(?groups_properties_order)) ?groups_properties_order
`;

const transformProperties = (properties: any) => {
  if (!properties) return;
  return groupBy(
    properties.map((v: any) => ({
      ...v,
      value: `${v.value}`,
      properties: transformProperties(v.properties),
    })),
    "predicate",
  );
};
