export const LABEL_PREDICATE_PATH = `skos:prefLabel|skosxl:prefLabel/skosxl:literalForm|rdfs:label|dct:title`;
const PREFIXES = `
  prefix skos: <http://www.w3.org/2004/02/skos/core#>
  prefix dct: <http://purl.org/dc/terms/>
  prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
  prefix triply: <https://triplydb.com/Triply/function/>
`;

/**
 * Main query, fetches a sorted list of top concepts with their scheme.
 */
export const sparqlFetchTopLevelConcepts = (filterSchemes: string[]) => `
  # sparqlFetchTopLevelConcepts
  ${PREFIXES}

  select distinct ?concept ?conceptLabel ?scheme where {
    values ?scheme { <${filterSchemes[0]}> }
    ?concept skos:topConceptOf ?scheme .
    bind(triply:firstLabel(?concept) as ?conceptLabel)
  }
  order by asc(?conceptLabel)
`;

export const allSchemesQuery = () => `
  # allSchemesQuery
    ${PREFIXES}

  select distinct ?conceptScheme ?label where {
    bind(skos:ConceptScheme as ?conceptSchemeClass)
    ?class rdfs:subClassOf* ?conceptSchemeClass .
    ?conceptScheme a ?class .
    bind(triply:firstLabel(?conceptScheme) as ?label)
  }
  order by asc(lcase(?label))

`;

/**
 * Fetches the selected resource on the right and its trail to the root so we can select it.
 */
const DIRECT = "skos:broader|^skos:narrower|skos:broadMatch|^skos:narrowMatch";
const INDIRECT = `(${DIRECT})*`;

/**
 * Constraints
 * The scheme of the last item is unknown; A user can view something in depth 2 while there is a depth of 5
 * Therefore we can only filter everything on all schemes, except for the first item. The top level item must be the first item from the concept schemes hierarchy
 */
export const sparqlFetchCurrentResource = (resource: string, filterSchemes: string[]) => {
  const filterSchemesIris = filterSchemes.map((scheme) => `<${scheme}>`).join(", ");

  return `
  # sparqlFetchCurrentResource
  ${PREFIXES}

  select ?end ?midLeft ?midRight ?begin
  where {
    bind(<${resource}> as ?end)
    ?end ${INDIRECT} ?counter .

    ?begin skos:inScheme ?beginScheme .
    filter (?beginScheme = <${filterSchemes.at(0)}>)
    ?midLeft skos:inScheme ?midLeftScheme .
    filter (?midLeftScheme in (${filterSchemesIris}))
    ?midRight skos:inScheme ?midRightScheme .
    filter (?midRightScheme in (${filterSchemesIris}))
    
    ?counter ${INDIRECT} ?midLeft .
    ?midLeft ${DIRECT} ?midRight .
    ?midRight ${INDIRECT} ?begin .
    filter not exists { ?begin ${DIRECT} [] }
  }
  group by ?end ?begin ?midLeft ?midRight 
  order by ?end ?begin count(?counter)

`;
};

/**
 * Fetches wether a skos concept is expandable / has sub terms.
 */
export const sparqlFetchExpandable = (values: string, filterSchemes: string[]) => `
  # sparqlFetchExpandable
  ${PREFIXES}

  select ?concept ?isExpandable where {
    values ?concept { ${values} }
    values ?scheme { ${filterSchemes.map((scheme) => `<${scheme}>`).join(" ")} }

    bind(exists {
      ?concept ^skos:broader|skos:narrower|^skos:broadMatch|skos:narrowMatch ?childConcept .
      ?childConcept skos:inScheme ?childScheme
      FILTER (?childScheme IN ( ${filterSchemes.map((scheme) => `<${scheme}>`).join(", ")} ))
    } as ?isExpandable)

    ?concept skos:inScheme ?scheme .
  }
`;

/**
 * Fetches tree items, children from a parent.
 */
export const sparqlFetchTreeChild = (concept: string, filterSchemes: string[]) => `
  # sparqlFetchTreeChild
  ${PREFIXES}

  select distinct ?concept ?conceptLabel ?scheme ?isExpandable where {
    bind (<${concept}> as ?parent)
    values ?scheme { ${filterSchemes.map((scheme) => `<${scheme}>`).join(" ")} }
    ?concept skos:broader|^skos:narrower|skos:broadMatch|^skos:narrowMatch ?parent .

    bind(exists {
      ?concept ^skos:broader|skos:narrower|^skos:broadMatch|skos:narrowMatch ?childConcept .
      ?childConcept skos:inScheme ?childScheme
      FILTER (?childScheme IN ( ${filterSchemes.map((scheme) => `<${scheme}>`).join(", ")}) )
    } as ?isExpandable)

    ?concept skos:inScheme ?scheme .
    bind(triply:firstLabel(?concept) as ?conceptLabel)
  }
  order by asc(?conceptLabel)
`;

export const sparqlSearch = (searchTerm: string, filterSchemes: string[]) => `
  # sparqlSearch
  ${PREFIXES}

  select distinct ?id ?label ?scheme ?schemeLabel where {
    ?id ${LABEL_PREDICATE_PATH} ?label .
    ?id skos:inScheme ?scheme .
    filter (?scheme IN ( ${filterSchemes.map((scheme) => `<${scheme}>`).join(", ")}) )
    filter (contains(lcase(?label), """${searchTerm.replace(/"""/g, "")}"""))
    bind(triply:firstLabel(?scheme) as ?schemeLabel)
  }
  limit 20
`;

export const conceptSchemeHierarchy = () => `
  # conceptSchemeHierarchy
  ${PREFIXES}

  select distinct ?parentConceptScheme ?childConceptScheme  {
    ?childConcept skos:inScheme ?childConceptScheme; skos:broadMatch ?parentConcept.
    ?parentConcept skos:inScheme ?parentConceptScheme.
    filter(?childConceptScheme != ?parentConceptScheme)
  }
`;
