/**
 * We need this top level import to avoid circular dependency issues.
 * See https://issues.triply.cc/issues/5980?tab=changesets#note-12
 */
import "#reducers/index.ts"; // eslint-disable-line import/no-duplicates
import { CacheProvider } from "@emotion/react";
import { createTheme, ThemeProvider } from "@mui/material";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import { ConnectedRouter, replace } from "connected-react-router";
import debug from "debug";
import { createBrowserHistory, History } from "history";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { CookiesProvider } from "react-cookie";
import * as ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider, useSelector } from "react-redux";
import RedBox from "redbox-react";
import { bindActionCreators, Store } from "redux";
import { ReduxAsyncConnect } from "redux-connect";
import ApiClient from "#helpers/ApiClient.ts";
import { GlobalState } from "#reducers/index.ts"; // eslint-disable-line import/no-duplicates
import getRoutes from "#routes.tsx";
import { getThemeOptions } from "#theme/mui.ts";
import registerIcons from "./helpers/FaIcons.tsx";
import createStore from "./store/create.ts"; //relative path. Otherwise conflicts with a package called 'store'
import createEmotionCache from "./theme/createEmotionCache.ts";
import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";

registerIcons();

const cache = createEmotionCache();

const client = new ApiClient(window.__data);
const dest = document.getElementById("content");
if (!dest) throw new Error(`No HTML element with id "content" found`);
if (!window.browserHistory) {
  window.browserHistory = createBrowserHistory();
}
if (__DEVELOPMENT__) {
  // Enable all places where we use the 'useWhatChanged' hook
  setUseWhatChange(true);
}

const history: History = window.browserHistory;

const log = debug("triply:history");
log("[%s] %s %O", history.action, history.location.pathname, history.location);
history.listen((location, action) => {
  log("[%s] %s %O", action, location.pathname, location);
});

declare var window: __App.ReactWindow;

const store: Store<GlobalState> =
  window.store ||
  createStore(history, client, {
    state: window.__data,
  });

const logo = `%c
   #########################    #####
 ###########################   #######
##   ##                        ##   ##
#######   ###########################
 #####    #########################
                     ###
              ####   ###
              ####   ###
              ####   ###
              ####   ###                              We are hiring!
              ####   ###                              https://triply.cc/career
              ####   ###
              ####   ###
              ####
              ##########
              ####   ####
              ####   ####
                ########
`;
console.info(logo, "color:#ed583a;font-weight:bold;");

const redirect = bindActionCreators(replace, store.dispatch);

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme = useSelector((state: GlobalState) => state.config.staticConfig?.theme.material);
  return (
    <ThemeProvider
      theme={createTheme(
        getThemeOptions(
          theme || {
            primary: "#1690c6",
            secondary: "#fa8d3e",
            error: "#f44336",
            success: "#5cb85c",
            warning: "#ff9800",
          },
        ),
      )}
    >
      {children}
    </ThemeProvider>
  );
};

function getComponents() {
  return (
    <Provider store={store} key="provider">
      <HelmetProvider>
        <CookiesProvider>
          <CacheProvider value={cache}>
            <CustomThemeProvider>
              <SnackbarProvider maxSnack={1} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
                <ConnectedRouter history={history as any}>
                  <ReduxAsyncConnect helpers={{ client, redirect }} routes={getRoutes(store)} />
                </ConnectedRouter>
              </SnackbarProvider>
            </CustomThemeProvider>
          </CacheProvider>
        </CookiesProvider>
      </HelmetProvider>
    </Provider>
  );
}
try {
  // When embedding, this point is called before hydration, making it the earliest point we can communicate the height
  if (window.location.search.includes("embed")) {
    window.parent.postMessage(document.body.clientHeight, "*");
  }
} catch (e) {
  // Probably the permission error
  console.error("Err", e);
}
try {
  ReactDOM.hydrateRoot(dest, getComponents());
} catch (error) {
  if (__DEVELOPMENT__) {
    ReactDOM.createRoot(dest).render(<RedBox error={error as Error} />);
  }
}

if (__DEVELOPMENT__) {
  window.React = React; // enable debugger

  if (!window.store) {
    window.store = store;
  }
}
