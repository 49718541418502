import { isArray } from "lodash-es";
import * as React from "react";
import { asyncConnect } from "redux-connect";
import { CachePolicies } from "use-http";
import { Models } from "@triply/utils";
import QueryJobsTable from "#components/QueryJobs/QueryJobsTable.tsx";
import { accountIsCurrentAccount, getAccountInfo, useCurrentAccount } from "#reducers/app.ts";
import { GlobalState } from "#reducers/index.ts";
import { AccountMetadata, ErrorPage, FlexContainer, Meta } from "../../components";
import useAcl from "../../helpers/hooks/useAcl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../helpers/hooks/useFetch";
import { useQueryJobsEnabled } from "../../helpers/hooks/useQueryJobsEnabled";
import { IComponentProps } from "..";

const QueryJobs: React.FC<IComponentProps> = ({ location }) => {
  const queryJobsEnabled = useQueryJobsEnabled();
  const acl = useAcl();
  const currentAccount = useCurrentAccount();
  const queryJobsUrl = useConstructUrlToApi()({
    pathname: `/jobs/${currentAccount?.accountName}`,
    fromBrowser: true,
  });

  const { get, data: queryJobs } = useFetch<Models.PipelineJob[]>(`${queryJobsUrl}`, {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const allJobsFinished =
    queryJobs &&
    isArray(queryJobs) &&
    queryJobs.every(
      (queryJob) =>
        (queryJob.status === "finished" || queryJob.status === "error" || queryJob.status === "cancelled") &&
        (queryJob.__unstable.pipeline.status === "finished" || queryJob.__unstable.pipeline.status === "error"),
    );

  React.useEffect(() => {
    if (queryJobsEnabled) {
      get().catch(() => {});
      if (!allJobsFinished) {
        const interval = setInterval(get, 45000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [get, queryJobsEnabled, allJobsFinished, location]);

  if (!currentAccount || !queryJobsEnabled) {
    return <ErrorPage statusCode={404} />;
  }
  const roleInAccount = acl.getRoleInAccount(currentAccount);
  if (!acl.check({ action: "readPipeline", context: { roleInOwnerAccount: roleInAccount } }).granted) {
    return <ErrorPage statusCode={401} />;
  }

  return (
    <>
      <AccountMetadata
        currentAccount={currentAccount}
        currentPath={location.pathname}
        title="Query Jobs - User settings"
      />
      {/* <Meta currentPath={location.pathname} title="Query jobs" /> */}
      {queryJobs && <QueryJobsTable queryJobs={queryJobs} loading={false} error={undefined} />}
    </>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: async ({ match: { params }, store: { dispatch, getState } }) => {
      if (!accountIsCurrentAccount(getState(), params.account)) {
        await dispatch<any>(getAccountInfo(getState(), params.account));
      }
    },
  },
])(QueryJobs) as typeof QueryJobs;
